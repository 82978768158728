import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Link} from 'gatsby';

import ServicesForm from './servicesForm';

import "./service.scss"

export default ({ pageContext }) => (
  <Layout>
    <SEO
      title={pageContext.yoast.title}
      description={pageContext.yoast.metadesc}
      ogimg={pageContext.yoast.opengraph_image.source_url}
      link={pageContext.link}
    />
    <div className={`${pageContext.slug} service-template`}>
      <div 
        className="lp-hero" 
        style={{ 
          backgroundImage: `url(https://www.meritmile.com/uploads/2021/09/hero-bg-${pageContext.slug}.jpg)`
        }}>
        <div className="container margin-b">
          <div className="row margin-b">
            <div className="col-md-8 hero-title">
              <h1>
                {pageContext.title}
                <span className="bar"></span>
              </h1>
            </div>
            <div className="col-md-4 hero-bg">
              <div className="inside-hero text-center">
                <h2>
                  Let's talk about your brand and vision today!
                </h2>
                <div className="form-wrapper">
                  <ServicesForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* EDITING ZONE */}
      
      {/* ENDOF Editing ZONE */}

      <div className="content-block" dangerouslySetInnerHTML={{__html: pageContext.content}}></div>

      <div className="servicesTabsWrapper">
        <div className="row no-gutters align-items-center">
            <div className="col-12 col-md advertising">
              <Link to="/services/advertising/">
                <span className="linkText">
                  Advertising
                  <span className="linkBar"></span>
                </span>
              </Link>
            </div>
            <div className="col-12 col-md interactive">
              <Link to="/services/interactive/">
                <span className="linkText">
                  Interactive
                  <span className="linkBar"></span>
                </span>
             </Link>
            </div>
            <div className="col-12 col-md pr">
              <Link to="/services/public-relations/">
                <span className="linkText">
                  Public Relations
                  <span className="linkBar"></span>
                </span>
             </Link>
            </div>
            <div className="col-12 col-md social">
              <Link to="/services/social-media/">
                <span className="linkText">
                  Social Media
                  <span className="linkBar"></span>
                </span>
             </Link>
            </div>
            <div className="col-12 col-md research">
              <Link to="/services/research/">
                <span className="linkText">
                  Research
                  <span className="linkBar"></span>
                </span>
             </Link>
            </div>
          </div>
      </div>

      <div className="graywrapper">
        <div className="container">
          <div className="row">
            <div className="col">
              <p className="text-center">
                For more about us please click a link below:
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 text-center">
              <p>
                <a href="/services/" className="btn btn-secondary">
                  Merit Mile Services
                </a>
              </p>
            </div>
            <div className="col-md-4 text-center">
              <p>
                <a href="/portfolio/" className="btn btn-secondary">
                  Merit Mile Portfolio
                </a>
              </p>
            </div>
            <div className="col-md-4 text-center">
              <p>
                <a href="/case-studies/" className="btn btn-secondary">
                  Merit Mile Case studies
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)
